import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

type OrganizationDerivation = {
  id: string;
  code: number;
  description: string;
  observations: string | null;
};

export const consultationsApi = createApi({
  reducerPath: 'consultationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.services.mediquo.chat_url,
    prepareHeaders: (headers) => {
      Object.entries(getHeaders()).forEach(([key, value]) =>
        headers.set(key, value!)
      );
      return headers;
    },
  }),
  tagTypes: ['Organization'],
  endpoints: (builder) => ({
    getOrganizationDerivations: builder.query<
      OrganizationDerivation[],
      { organizationId: string }
    >({
      query: ({ organizationId }) =>
        `/consultations/v1/organization-derivations/${organizationId}`,
      transformResponse: (response: { data: OrganizationDerivation[] }) =>
        response.data,
      providesTags: (result, error, { organizationId }) => [
        { type: 'Organization', id: organizationId },
      ],
    }),
  }),
});

export const { useGetOrganizationDerivationsQuery } = consultationsApi;
