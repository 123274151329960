const Paper = (props: any) => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.8244 31.9763V19.9118C31.8244 19.6558 31.7262 19.4096 31.5501 19.2238L27.7813 15.2485C27.5925 15.0493 27.3301 14.9365 27.0556 14.9365H18.845C18.2927 14.9365 17.845 15.3842 17.845 15.9365V31.9763C17.845 32.5286 18.2927 32.9763 18.845 32.9763H20.7427H30.8244C31.3767 32.9763 31.8244 32.5286 31.8244 31.9763Z"
      stroke="currentColor"
      stroke-width="1.9"
    />
  </svg>
);
export default Paper;
