import { FC } from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import classNames from 'classnames';

export interface Props {
  title: string;
  content: string;
  cancelText?: string;
  confirmText: string;
  onCancel?: () => void;
  onConfirm: () => void;
  petitions?: boolean;
}

const Confirmation: FC<Props> = ({
  title,
  content,
  cancelText,
  confirmText,
  onCancel = () => {},
  onConfirm,
  petitions = false,
}) => {
  const handleOnClose = (value?: unknown) =>
    value === 'confirm' ? onConfirm() : onCancel();

  return (
    <Modal
      allowOutsideClose={false}
      onClose={handleOnClose}
      width="narrow"
      position="top"
      backdropTransparent
    >
      {({ onClose }: { onClose: (value: string) => void }) => (
        <>
          <Modal.Header className="bg-background border-none" isCloseHidden>
            <p className="text-base text-primary bg-background">{title}</p>
          </Modal.Header>
          <Modal.Body>
            <p className="text-sm">{content}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => onClose('confirm')}
              size="compact"
              className={classNames({ 'bg-negative-dark ': petitions })}
            >
              {confirmText}
            </Button>
            {cancelText && (
              <Button
                variant="reverse"
                size="compact"
                onClick={() => onClose('reverse')}
                className={classNames({
                  'bg-secundary text-primary border-none hover:bg-dark hover:text-white':
                    petitions,
                })}
              >
                {cancelText}
              </Button>
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default Confirmation;
