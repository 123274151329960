import { useRef, useState, KeyboardEvent, useMemo, useEffect } from 'react';
import { Input } from './Input';
import { OptionsList } from './OptionsList';

export interface AutocompleteProps {
  value?: string;
  onChange?: (value: string) => void;
  options: Array<{
    label: string;
    value: string;
  }>;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  emptyMessage?: string;
  isLoading?: boolean;
  isError?: boolean;
  disabled?: boolean;
}

export const Autocomplete = ({
  options,
  label,
  placeholder,
  isRequired,
  value,
  onChange,
  emptyMessage,
  isLoading = false,
  isError = false,
  disabled = false,
}: AutocompleteProps) => {
  const [open, setOpen] = useState(false);
  const [inputText, setText] = useState(() => {
    if (value) {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption?.label ?? '';
    }
    return '';
  });
  const [isOptionSelected, setIsOptionSelected] = useState(!!value);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value) {
      const selectedOption = options.find((option) => option.value === value);
      if (selectedOption) {
        setText(selectedOption.label);
        setIsOptionSelected(true);
      }
    }
  }, [value, options]);

  const filteredOptions = useMemo(
    () =>
      open
        ? isOptionSelected
          ? options
          : options.filter((option) =>
              option.label.toLowerCase().includes(inputText.toLowerCase())
            )
        : options,
    [options, inputText, open, isOptionSelected]
  );

  const handleOptionSelect = (option: { label: string; value: string }) => {
    setText(option.label);
    onChange?.(option.value);
    setIsOptionSelected(true);
    setOpen(false);
  };

  const handleInputChange = (value: string) => {
    setText(value);
    setOpen(true);
    setHighlightedIndex(0);
    setIsOptionSelected(false);
    if (onChange) {
      onChange('');
    }
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!open) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        setOpen(true);
        e.preventDefault();
      }
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        setHighlightedIndex((prev) =>
          prev < filteredOptions.length - 1 ? prev + 1 : prev
        );
        e.preventDefault();
        break;
      case 'ArrowUp':
        setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : prev));
        e.preventDefault();
        break;
      case 'Enter':
        if (filteredOptions[highlightedIndex]) {
          handleOptionSelect(filteredOptions[highlightedIndex]);
        }
        e.preventDefault();
        break;
      case 'Escape':
        setOpen(false);
        e.preventDefault();
        break;
    }
  };

  return (
    <div className="relative">
      <Input
        ref={inputRef}
        label={label}
        isRequired={isRequired}
        placeholder={placeholder}
        value={inputText}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        isError={isError}
        disabled={disabled}
      />

      <OptionsList
        isOpen={open}
        options={filteredOptions}
        onOptionSelect={handleOptionSelect}
        onClose={() => setOpen(false)}
        highlightedIndex={highlightedIndex}
        onHighlight={setHighlightedIndex}
        emptyMessage={emptyMessage}
        isLoading={isLoading}
        selectedValue={value}
      />
    </div>
  );
};
