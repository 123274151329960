import { Resolver } from 'react-hook-form';
import { z } from 'zod';

/**
 * Temporal resolver to connect react-hook-form legacy v6 with latest version of zod.
 * NOTE: install @hookform/resolvers when updating to react-hook-form v7
 * and remove this function. Use zodResolver from @hookform/resolvers/zod instead.
 */
export const zodResolver = <T extends z.ZodType<any, any>>(
  schema: T
): Resolver<z.TypeOf<T>, object> => {
  return (data) => {
    const parsed = schema.safeParse(data);
    if (parsed.success) {
      return { values: parsed.data, errors: {} };
    }
    const errors: Record<string, any> = {};

    for (const issue of parsed.error.issues) {
      let deepError = errors;

      for (let i = 0, n = issue.path.length; i < n; i++) {
        if (!deepError[issue.path[i]]) {
          deepError[issue.path[i]] = {};
        }
        deepError = deepError[issue.path[i]];
      }

      deepError['type'] = issue.code;
      deepError['message'] = issue.message;
    }

    return { values: {}, errors };
  };
};
