import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

const BASE_URL = config.services.mediquo.chat_url;
export const CONSULTATIONS = 'consultations/v1';

const start = ({ consultation_id }: { consultation_id: string }) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${CONSULTATIONS}/chat/${consultation_id}/start`,
    headers: getHeaders(),
  });

const finish = ({
  consultation_id,
  derivation_id,
}: {
  consultation_id: string;
  derivation_id?: string;
}) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${CONSULTATIONS}/chat/${consultation_id}/finish`,
    headers: getHeaders(),
    data: {
      derivation_id,
    },
  });

export default {
  start,
  finish,
};
