import { cn } from 'components/utils';
import { ComponentProps, forwardRef, KeyboardEvent } from 'react';

interface InputProps {
  label?: string;
  isRequired?: boolean;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onFocus: () => void;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  isError?: boolean;
}

const LensIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 17.5L12.5001 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ArrowDownIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.20006 4.4C1.53143 3.95817 2.15823 3.86863 2.60006 4.2L6.00006 6.75L9.40006 4.2C9.84189 3.86863 10.4687 3.95817 10.8001 4.4C11.1314 4.84183 11.0419 5.46863 10.6001 5.8L6.60006 8.8C6.2445 9.06667 5.75561 9.06667 5.40006 8.8L1.40006 5.8C0.958231 5.46863 0.868688 4.84183 1.20006 4.4Z"
      fill="white"
    />
  </svg>
);

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      isRequired,
      placeholder,
      value,
      onChange,
      onFocus,
      onKeyDown,
      disabled,
      isError = false,
    },
    ref
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    };

    return (
      <div
        className={cn(
          'bg-[#1B1B1B] text-gray-light py-2 px-4 rounded-lg flex items-center gap-3',
          disabled ? 'opacity-50 cursor-not-allowed' : '',
          isError ? 'border border-negative' : ''
        )}
        onClick={() => {
          if (disabled) return;
          if (ref && 'current' in ref) {
            ref.current?.focus();
          }
          onFocus();
        }}
      >
        <LensIcon />
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor={label} className="block text-sm font-medium">
            {label}
            {isRequired && '*'}
          </label>
          <input
            ref={ref}
            className="w-full h-5 min-h-[20px] bg-transparent text-sm text-left focus-visible:!ring-0"
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            disabled={disabled}
          />
        </div>
        <ArrowDownIcon className="cursor-pointer" />
      </div>
    );
  }
);

Input.displayName = 'Input';
