import { Dispatch, SetStateAction } from 'react';
import { cn } from 'components/utils';
import useOutside from 'utils/useOutside';
import { Option } from './Option';

interface OptionsListProps {
  isOpen: boolean;
  options: Array<{ label: string; value: string }>;
  onOptionSelect: (option: { label: string; value: string }) => void;
  onClose: () => void;
  highlightedIndex: number;
  onHighlight: Dispatch<SetStateAction<number>>;
  emptyMessage?: string;
  isLoading?: boolean;
  selectedValue?: string;
}

export const OptionsList = ({
  isOpen,
  options,
  onOptionSelect,
  onClose,
  highlightedIndex,
  onHighlight,
  emptyMessage,
  isLoading,
  selectedValue,
}: OptionsListProps) => {
  const contentRef = useOutside(onClose);
  if (!isOpen) return null;

  return (
    <div
      ref={contentRef}
      className={cn(
        'absolute top-full left-0 z-50 w-full bg-[#1B1B1B] text-gray-light rounded-lg translate-y-2 flex flex-col',
        'transition-all duration-200 ease-out animate-in fade-in slide-in-from-top-2',
        'py-2 max-h-[240px] overflow-y-auto'
      )}
    >
      {isLoading ? (
        <div className="space-y-2 px-4 py-2">
          <div className="h-8 bg-gray-darker rounded animate-pulse" />
          <div className="h-8 bg-gray-darker rounded animate-pulse" />
          <div className="h-8 bg-gray-darker rounded animate-pulse" />
        </div>
      ) : options.length === 0 ? (
        <div className="px-4 py-2 text-sm text-center text-gray">
          {emptyMessage}
        </div>
      ) : (
        options.map((option, index) => (
          <Option
            key={option.value}
            option={option}
            isHighlighted={index === highlightedIndex}
            isSelected={option.value === selectedValue}
            onSelect={() => onOptionSelect(option)}
            onMouseEnter={() => onHighlight(index)}
          />
        ))
      )}
    </div>
  );
};
