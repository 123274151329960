import { cn } from 'components/utils';
import { ReactNode } from 'react';

export interface FormFieldProps {
  label?: string | ReactNode;
  htmlFor?: string;
  helperText?: string;
  errorMessage?: string;
  children?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  errorVariant?: 'default' | 'light';
}

export const FormLabel = ({
  children,
  htmlFor,
  required = false,
  disabled = false,
}: {
  children: ReactNode;
  required?: boolean;
  disabled?: boolean;
  htmlFor?: string;
}) => (
  <label
    className={cn(
      'block text-sm font-medium',
      disabled ? 'text-gray-dark' : 'text-dark'
    )}
    htmlFor={htmlFor}
  >
    {children}
    {required ? '*' : ''}
  </label>
);

export const FormField = ({
  label,
  htmlFor,
  helperText,
  errorMessage,
  disabled = false,
  required = false,
  children,
  className,
  errorVariant = 'default',
}: FormFieldProps) => {
  const labelSection =
    typeof label === 'string' ? (
      <FormLabel htmlFor={htmlFor} required={required} disabled={disabled}>
        {label}
      </FormLabel>
    ) : (
      label
    );

  return (
    <fieldset className={className}>
      {labelSection}
      {helperText ? (
        <p
          className={cn(
            'text-sm mb-2',
            disabled ? 'text-gray-medium' : 'text-gray-dark'
          )}
        >
          {helperText}
        </p>
      ) : null}
      {children}
      {!disabled && errorMessage ? (
        <p
          className={cn(
            'mt-2 text-sm',
            errorVariant === 'light' ? 'text-negative-light' : 'text-negative'
          )}
        >
          {errorMessage}
        </p>
      ) : null}
    </fieldset>
  );
};
