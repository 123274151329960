import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

const API = 'diagnostic-tests/v1';
const BASE_URL = config.services.mediquo.chat_url;

interface Prescription {
  contact_id: string;
  consultation_type?: string;
  consultation_id?: string;
  observations?: string;
  item_ids: string[];
  sessions?: number;
  laterality?: string[];
  report_url?: string;
}

interface Template {
  title: string;
  item_ids: string[];
  id?: string;
}

const fetchPrescriptions = ({ hash }: { hash: string }) =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/patients/${hash}/prescriptions`,
    headers: getHeaders(),
  });

const savePrescriptions = (prescription: Prescription) =>
  axios({
    method: 'POST',
    url: `${BASE_URL}/${API}/prescriptions`,
    headers: getHeaders(),
    data: prescription,
  });

const categories = ({ patient_hash }: { patient_hash: string }) =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/categories`,
    params: { patient_hash },
    headers: getHeaders(),
  });

const items = ({
  categoryId,
  patient_hash,
}: {
  categoryId: string;
  patient_hash: string;
}) =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/categories/${categoryId}/items`,
    params: { patient_hash },
    headers: getHeaders(),
  });

const saveTemplate = (template: Template) =>
  axios({
    method: 'POST',
    url: `${BASE_URL}/${API}/templates`,
    headers: getHeaders(),
    data: template,
  });

const updateTemplate = (template: Template) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/templates/${template.id}`,
    headers: getHeaders(),
    data: template,
  });

const getTemplates = () =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/templates`,
    headers: getHeaders(),
  });

const deleteTemplate = (templateId: string) =>
  axios({
    method: 'DELETE',
    url: `${BASE_URL}/${API}/templates/${templateId}`,
    headers: getHeaders(),
  });

const storage = () => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/storage`,
    headers: getHeaders(),
  });
};

export default {
  categories,
  items,
  savePrescriptions,
  fetchPrescriptions,
  saveTemplate,
  updateTemplate,
  getTemplates,
  deleteTemplate,
  storage,
};
