import { DerivationsAutocomplete } from 'components/DerivationsAutocomplete';
import Spinner from 'components/icons/outline/Spinner';
import { FormField } from 'components/primitives/FormField';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationDerivationsQuery } from 'services/consultations';
import { zodResolver } from 'utils/zodResolver';
import { z } from 'zod';

interface DerivationFormProps {
  formId?: string;
  onSubmit: (data: { derivation: string }) => void;
  organizationId: string;
  isRequired: boolean;
  defaultValues?: {
    derivation: string;
  };
  disabled?: boolean;
}

export const DerivationForm = ({
  formId = 'derivation-form',
  onSubmit,
  organizationId,
  isRequired,
  defaultValues,
  disabled,
}: DerivationFormProps) => {
  const DerivationFormSchema = z.object({
    derivation: z
      .string()
      .min(isRequired ? 1 : 0, 'derivations__autocomplete_required_error'),
  });
  const { t } = useTranslation();
  const { data, isLoading } = useGetOrganizationDerivationsQuery(
    { organizationId },
    { skip: !organizationId }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof DerivationFormSchema>>({
    resolver: zodResolver(DerivationFormSchema),
    defaultValues: {
      derivation: defaultValues?.derivation ?? '',
    },
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner className="w-6 h-6 text-gray animate-spin" />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="derivation"
        render={({ value, onChange }) => (
          <FormField
            errorMessage={t(errors.derivation?.message ?? '')}
            errorVariant="light"
          >
            <DerivationsAutocomplete
              isRequired={isRequired}
              disabled={disabled}
              value={value}
              onChange={onChange}
              isError={!!errors.derivation}
              organizationId={organizationId}
            />
          </FormField>
        )}
      />
    </form>
  );
};
