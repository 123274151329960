import classnames from 'classnames';
import PatientBar from 'containers/DetailBar/PatientBar/PatientBar';
import RoomBody from 'containers/Room/components/RoomBody/RoomBody';
import RoomInput from 'containers/Room/components/RoomInput/RoomInput';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HangUpIcon from 'containers/VideoCall/icons/HangUpIcon';
import User from 'components/icons/outline/User';
import classNames from 'classnames';
import ChatBadge from 'components/icons/outline/ChatBadge';
import { joinRoom } from 'containers/Room/actions/JoinRoom.actions';
import { useTranslation } from 'react-i18next';
import Reporting from 'containers/Appointments/Reporting';
import Cross from 'components/icons/outline/Cross';
import MedicalReportCall from 'containers/MedicalReport/MedicalReportCall';
import Tracker from 'utils/Tracking';
import Caret from 'components/icons/outline/Caret';
import Flag from 'components/icons/outline/Flag';
import { usePortal } from 'containers/Shared/components/Portal';
import useAppointment from 'containers/Appointments/useAppointment';
import useMedicalReport from 'containers/MedicalReport/useMedicalReport';
import { useHistory } from 'react-router';
import Confirmation from 'components/Modal/Confirmation';
import { useForm } from 'react-hook-form';
import uuid from 'uuid/v4';
import {
  useGetAllReportsQuery,
  useUpsertDraftReportMutation,
} from 'services/reports';
import Spinner from 'components/Spinner';
import CheckIcon from 'containers/Login/icons/CheckIcon';
import ScreenHeader from 'containers/DetailBar/PatientBar/components/common/ScreenHeader';
import Image from 'containers/Shared/components/Image';
import { MicrophoneControl } from 'containers/VideoCall/CallView';
import usePhoneCall from 'containers/PhoneCall/usePhoneCall';
import dayjs from 'dayjs';
import useInterval from 'utils/useInterval';
import NotPresentModal from 'components/Appointment/NotPresentModal';
import FlagSolid from 'components/icons/solid/FlagSolid';

const Call = ({
  roomId,
  onFinish,
  onExit,
  consultationType,
  consultationId,
  status,
  patient,
  appointment,
  setStatus,
}) => {
  const room = useSelector((state) => state.console.current);
  const [tab, setTab] = useState('profile');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const portal = usePortal();
  const { notPresent, complete } = useAppointment();
  const history = useHistory();
  const inCall = useSelector((state) => state.waitingRoom.inCall);
  const [hoverNotPresent, setHoverNotPresent] = useState(false);
  const [profileCollapsed, setProfileCollapsed] = useState(false);
  const { startPhoneCall, handleHangUp, mutePhoneCall } = usePhoneCall();
  const phoneCall = useSelector((state) => state?.phoneCall);
  const phoneNumber = phoneCall?.current?.to?.full_phone_number ?? '';

  const [counter, setCounter] = useState(0);
  const [timer, setTimer] = useState(null);
  const { isMuted, status: phoneCallStatus } = useSelector(
    (state) => state.phoneCall
  );

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP' && inCall) {
        portal.open(
          <Confirmation
            title={t('videocall__navigation_modal_title')}
            content={t('videocall__navigation_modal_content')}
            confirmText={t('videocall__navigation_modal_confirm_text')}
            onConfirm={() => {
              portal.close('confirmation');
            }}
          />,
          'confirmation'
        );
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, []);

  const handleJoinCall = async () => {
    startPhoneCall({
      userHash: patient.hash,
      consultationId: appointment.id,
      consultationType: 'appointment',
      isAppointment: true,
    });
  };

  useEffect(() => {
    dispatch(joinRoom(roomId));
    handleJoinCall();
  }, []);

  const finishPhoneCall = async () => {
    await handleHangUp();
    await onFinish();
    await complete({ appointment });
  };

  useInterval(() => {
    if (['connected'].includes(phoneCall.status)) {
      setCounter(counter + 1000);
    }
  }, 1000);

  useEffect(() => {
    const time = dayjs(counter).format('m:ss');
    setTimer(time);
  }, [counter]);

  useEffect(() => {
    if (phoneCallStatus === 'finished') {
      setTimeout(() => {
        setCounter(0);
        onFinish();
      }, 2000);
    }
  }, [phoneCallStatus]);

  const markAsNotPresent = async () => {
    handleHangUp();
    await notPresent({ appointmentId: appointment.id });
    await portal.close('modal_not_present');
    await setTimeout(() => {
      handleHangUp();
      onExit();
    }, 2000);
  };

  const openNotPresentModal = () => {
    portal.open(
      <NotPresentModal
        handleClose={() => portal.close('modal_not_present')}
        handleConfirm={markAsNotPresent}
      />,
      'modal_not_present'
    );
  };

  return (
    <div
      className={classnames('flex w-full relative gap-3 py-3 pl-7', {
        'px-7': !profileCollapsed,
      })}
    >
      <div className="w-full bg-white">
        <div
          id="meet_wrap"
          className="relative h-full rounded-24 overflow-hidden overflow-y-auto bg-background-dark"
        >
          <div id="mute-audio" />
          <div id="call-hangup" />
          {status === 'reporting' ? (
            <Reporting
              patient={patient}
              room={room}
              status={status}
              consultationType={consultationType}
              consultationId={consultationId}
              appointment={appointment}
              isAppointmentAction={false}
              setProfileCollapsed={setProfileCollapsed}
              setTab={setTab}
              setStatus={setStatus}
              isPhoneCall
              handleJoinCall={handleJoinCall}
              onExit={onExit}
            />
          ) : (
            <div className="h-full">
              <span className="absolute top-6 left-6 z-10 flex flex-col text-sm text-white">
                <button
                  className="flex flex-row justify-center items-center"
                  onClick={() => {
                    handleHangUp();
                    onExit();
                  }}
                >
                  <Caret className="mr-2 w-2.5 rotate-90" />
                  {t('consultations__meet_not_present_return')}
                </button>

                <div />
              </span>

              <span className="absolute top-6 right-6 flex flex-col z-10">
                <button
                  className="flex flex-row text-white text-sm items-center"
                  onClick={openNotPresentModal}
                  onMouseEnter={() => setHoverNotPresent(true)}
                  onMouseLeave={() => setHoverNotPresent(false)}
                >
                  <div className="w-fit">
                    <span
                      className={classNames({
                        'border-b border-white': hoverNotPresent,
                      })}
                    >
                      {t('consultations__meet_not_present_button')}
                    </span>
                  </div>
                  <div className="w-8 h-8 bg-[#404040] ml-1 p-2 rounded-full">
                    {hoverNotPresent ? (
                      <FlagSolid className="w-4 -translate-y-3 " />
                    ) : (
                      <Flag className="w-4" />
                    )}
                  </div>
                </button>
              </span>

              <div className="absolute flex w-full bottom-6 justify-center gap-2.5 z-10">
                <MicrophoneControl toggle={mutePhoneCall} audio={!isMuted} />
                <button
                  onClick={finishPhoneCall}
                  className={classnames(
                    'flex items-center justify-center rounded-2xl w-12 h-12 bg-negative'
                  )}
                >
                  <HangUpIcon />
                </button>
              </div>

              <div className="w-full h-full flex items-center justify-center">
                <div className="max-w-[300px] mx-auto text-center">
                  <span className="text-white text-lg ">
                    <div className="flex m-auto flex-col gap-2.5 justify-center items-center">
                      <Image
                        placeholder={patient?.name}
                        src={patient?.avatar}
                        alt={patient.name}
                        className="w-20 h-20 rounded-full"
                      />
                      <span>{patient.name}</span>
                      <span>{phoneNumber}</span>
                      <span
                        className={classNames(
                          'text-md font-medium normal-case',
                          {
                            'text-negative': ['error', 'declined'].includes(
                              phoneCall?.status
                            ),
                          }
                        )}
                      >
                        {[
                          'calling',
                          'declined',
                          'error',
                          'connecting',
                          'finished',
                          'busy',
                        ].includes(phoneCall?.status)
                          ? `${t(`sidebar__phone_call_${phoneCall?.status}`)} ${
                              phoneCall?.status === 'finished' ? timer : ''
                            }`
                          : timer}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={classnames('flex h-full', {
          'min-w-[436px] w-[436px] max-w-[436px] flex-shrink-0 ':
            !profileCollapsed,
        })}
      >
        <div className="flex flex-col gap-2">
          <TabButton
            isActive={tab === 'profile' || tab === 'report'}
            onClick={() => {
              setTab('profile');
              setProfileCollapsed(false);
            }}
            profileCollapsed={profileCollapsed}
          >
            <User className="h-6 w-6" />
          </TabButton>
          <TabButton
            isActive={tab === 'chat'}
            onClick={() => {
              setTab('chat');
              setProfileCollapsed(false);
            }}
            profileCollapsed={profileCollapsed}
          >
            <ChatBadge className="h-6 w-6" />
          </TabButton>
        </div>
        <div
          className={classnames({
            'h-full w-full flex flex-col border border-separators rounded-xl rounded-tl-none overflow-hidden':
              !profileCollapsed,
            hidden: profileCollapsed,
          })}
        >
          {
            {
              profile: (
                <Profile
                  roomId={roomId}
                  room={room}
                  consultationType={consultationType}
                  consultationId={consultationId}
                  setProfileCollapsed={setProfileCollapsed}
                  setTab={setTab}
                />
              ),
              chat: (
                <Chat
                  room={room}
                  consultationType={consultationType}
                  consultationId={consultationId}
                  setProfileCollapsed={setProfileCollapsed}
                />
              ),
              report: (
                <Report
                  roomId={roomId}
                  room={room}
                  consultationType={consultationType}
                  consultationId={consultationId}
                  setTab={setTab}
                />
              ),
            }[tab]
          }
        </div>
      </div>
    </div>
  );
};

const Chat = ({
  room,
  consultationType,
  consultationId,
  setProfileCollapsed,
}) => {
  return (
    <>
      <div className="flex flex-row justify-between p-6 border-b border-separators">
        <span className="text-dark">Chat</span>
        <button onClick={() => setProfileCollapsed(true)}>
          <Cross className="text-gray-medium w-3.5 ml-auto" />
        </button>
      </div>
      <RoomBody room={room} />
      <RoomInput
        room={room}
        consultationType={consultationType}
        consultationId={consultationId}
      />
    </>
  );
};

const Profile = ({
  roomId,
  consultationType,
  consultationId,
  setProfileCollapsed,
  setTab,
}) => {
  const { t } = useTranslation();
  const { screen } = useSelector((state) => state.patientBar);
  return (
    <div className="h-full">
      {!['report'].includes(screen) && (
        <div className="flex flex-row justify-between p-6 border-b border-separators">
          <span className="text-dark">
            {t('sidebar__patient_header_title')}
          </span>
          <button onClick={() => setProfileCollapsed(true)}>
            <Cross className="text-gray-medium w-3.5 ml-auto" />
          </button>
        </div>
      )}
      <PatientBar
        showHeader={false}
        roomId={roomId}
        width="full"
        consultationType={consultationType}
        consultationId={consultationId}
        setProfileCollapsed={setProfileCollapsed}
        setTab={setTab}
      />
    </div>
  );
};

const TabButton = ({ children, onClick, isActive, profileCollapsed }) => (
  <button
    className={classNames(
      'w-10 h-10 flex items-center justify-center rounded-tl-lg rounded-bl-lg',
      {
        'text-dark bg-secundary': !isActive || profileCollapsed,
        'text-white bg-primary': isActive && !profileCollapsed,
      }
    )}
    onClick={onClick}
  >
    {children}
  </button>
);

export const Report = ({
  roomId,
  room,
  consultationId,
  consultationType,
  setTab,
}) => {
  const { t } = useTranslation();
  const { handleChangeScreen } = useMedicalReport();
  const { data } = useGetAllReportsQuery(
    { patient_hash: room?.meta?.hash },
    { skip: !room?.meta?.hash }
  );
  const currentDraft = data?.draftReport;

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      subjective: currentDraft?.subjective_data,
      objective: currentDraft?.objective_data,
      diagnostic_id:
        currentDraft?.diagnostic?.id ?? currentDraft?.diagnostic_id,
      plan: currentDraft?.plan,
    },
  });
  const {
    formState: { isDirty },
    watch,
  } = methods;
  const formData = watch();
  const [mutateSaveDraft, { isLoading: isSavingDraft, isSuccess }] =
    useUpsertDraftReportMutation();
  const patientHash = room?.meta?.hash;

  const handleCancelReport = () => {
    if (isDirty) {
      Tracker.event('reports cancel', {
        event_room_id: room?.room_id,
      });

      mutateSaveDraft({
        report_id: currentDraft?.uuid ?? uuid(),
        data: {
          customer_hash: patientHash,
          consultation_id: consultationId,
          consultation_type: consultationType,
          plan: formData.plan,
          diagnostic_id: formData.diagnostic_id,
          objective_data: formData.objective,
          subjective_data: formData.subjective,
        },
      });
    }

    setTab?.('profile');
    handleChangeScreen('profile');
  };

  return (
    <>
      <ScreenHeader
        title={t('sidebar__patient_create_report')}
        onBack={handleCancelReport}
        endSection={
          <div className="inline-flex items-center text-sm font-light">
            {isSavingDraft ? (
              <>
                <span>{t('appointments__meet__saving_draft')}</span>
                <Spinner className="w-3 h-3 ml-2" />
              </>
            ) : isSuccess ? (
              <>
                <span>{t('appointments__meet__saved_draft')}</span>
                <CheckIcon className="w-3 h-3 ml-2" />
              </>
            ) : (
              t('sidebar__patient_clinical_courses_draft')
            )}
          </div>
        }
      />
      <MedicalReportCall
        formMethods={methods}
        setTab={setTab}
        roomId={roomId}
        patientHash={patientHash}
        consultation_type={consultationType}
        consultation_id={consultationId}
        onAutoSave={mutateSaveDraft}
      />
    </>
  );
};

export default Call;
