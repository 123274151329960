import { useGetOrganizationDerivationsQuery } from 'services/consultations';
import { Autocomplete } from '../primitives/Autocomplete';
import { useTranslation } from 'react-i18next';

interface DerivationsAutocompleteProps {
  organizationId: string;
  value: string;
  onChange: (value: string) => void;
  isRequired?: boolean;
  isError?: boolean;
  disabled?: boolean;
}

export const DerivationsAutocomplete = ({
  value,
  onChange,
  isRequired = false,
  isError = false,
  organizationId,
  disabled,
}: DerivationsAutocompleteProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetOrganizationDerivationsQuery(
    { organizationId },
    { skip: !organizationId }
  );

  return (
    <Autocomplete
      isError={isError}
      isLoading={isLoading}
      isRequired={isRequired}
      label={t('derivations__autocomplete_label')}
      placeholder={t('derivations__autocomplete_placeholder')}
      emptyMessage={t('derivations__autocomplete_empty_message')}
      options={
        data?.map((derivation) => ({
          label: derivation.description,
          value: derivation.id,
        })) ?? []
      }
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
