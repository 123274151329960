import { cn } from 'components/utils';
import { FC } from 'react';

export interface Props {
  variant?:
    | 'primary'
    | 'secondary'
    | 'reverse'
    | 'custom'
    | 'clear'
    | 'light'
    | 'plain-primary'
    | 'plain-negative';
  size?: 'normal' | 'small' | 'icon' | 'custom' | 'compact';
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset' | 'link';
  className?: string;
  href?: string;
  target?: string;
  dataTip?: string;
  form?: string;
  onClick?: (event: any) => void;
}

const Button: FC<Props> = ({
  variant = 'primary',
  size = 'normal',
  active = true,
  disabled = false,
  loading = false,
  type,
  className,
  target,
  href,
  onClick,
  dataTip,
  form,
  children,
}) => {
  const styles = cn(
    'uppercase font-bold rounded-lg flex items-center justify-center',
    {
      normal: 'text-sm px-6 py-4',
      small: 'text-xs p-2',
      icon: 'h-8 w-8',
      custom: '',
      compact: 'text-xs px-5 py-3',
    }[size],
    active && !disabled
      ? {
          primary: 'text-white bg-primary hover:bg-primary-dark',
          secondary: 'text-primary bg-secundary',
          reverse: 'text-primary border-primary border hover:bg-background',
          clear: 'text-gray-medium hover:text-gray-dark hover:bg-gray-light',
          light:
            'text-gray-medium border-separators border hover:bg-gray-light hover:border-gray-light',
          'plain-primary': 'text-primary hover:bg-primary',
          'plain-negative': 'text-negative hover:bg-negative',
          custom: '',
        }[variant]
      : 'text-gray-medium bg-gray-light',
    {
      'animate animate-pulse': loading,
      'hover:bg-opacity-5': ['plain-negative', 'plain-primary'].includes(
        variant
      ),
    },
    ['plain-negative', 'plain-primary'].includes(variant)
      ? 'tracking-widest'
      : 'tracking-widest-xl',
    { 'cursor-not-allowed': disabled },
    { 'bg-white opacity-50': disabled && variant === 'clear' },
    className
  );

  if (type === 'link') {
    return (
      <a className={styles} data-tip={dataTip} href={href} target={target}>
        {children}
      </a>
    );
  }

  return (
    <button
      data-tip={dataTip}
      type={type}
      disabled={disabled}
      form={form}
      className={styles}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
