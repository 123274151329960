import classNames from 'classnames';
import classnames from 'classnames';
import { CloseIcon } from 'components/Drawer';
import Clip from 'components/icons/outline/Clip';
import Paper from 'components/icons/solid/Paper';
import { useState } from 'react';

const File = ({
  name,
  label,
  handleChange,
  file,
  setFile,
  placeholder,
  fileRef,
}: any) => {
  const [error, setError] = useState(false);
  return (
    <div className={classnames('input_container')}>
      {!file && (
        <label
          htmlFor={name}
          className={classNames(
            'flex flex-row items-center gap-2 bg-secundary text-primary px-2.5 py-1.5 rounded-lg shrink-0 w-fit uppercase text-xs font-medium tracking-wider cursor-pointer',
            {
              'cursor-not-allowed': file?.name,
            }
          )}
        >
          <Clip className="min-w-4 h-4" />

          {label}
          <input
            type="file"
            id="file-input"
            name={name}
            className="hidden"
            onChange={handleChange}
            disabled={file?.name}
            ref={fileRef}
          />
        </label>
      )}
      <div className="my-2">
        {file?.name ? (
          <div className="flex flex-row border border-gray-light rounded-lg p-0.5 items-center gap-2 w-[260px]">
            <Paper className="w-16 h-10 bg-gray-light rounded-lg text-gray-dark" />
            <div className="text-sm text-dark font-medium max-w-60 truncate">
              {file?.name}
            </div>

            <div className="mr-2">
              <CloseIcon
                name="close"
                className="text-gray-dark text-sm cursor-pointer w-2.5"
                onClick={() => {
                  setFile(null);
                  setError(true);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className={classnames(
              'text-xs',
              error ? 'text-negative' : 'text-gray-dark'
            )}
          >
            {placeholder}
          </div>
        )}
      </div>
    </div>
  );
};

export default File;
