import { cn } from 'components/utils';
import Check from 'components/icons/outline/Check';

interface OptionProps {
  option: { label: string; value: string };
  isHighlighted: boolean;
  isSelected: boolean;
  onSelect: () => void;
  onMouseEnter: () => void;
}

export const Option = ({
  option,
  isHighlighted,
  isSelected,
  onSelect,
  onMouseEnter,
}: OptionProps) => {
  return (
    <button
      type="button"
      className={cn(
        'px-4 py-2 hover:bg-[#404040] text-left flex items-center justify-between',
        isHighlighted && 'bg-[#404040]'
      )}
      onClick={onSelect}
      onMouseEnter={onMouseEnter}
    >
      <span>{option.label}</span>
      {isSelected && <Check className="w-4 h-4" />}
    </button>
  );
};
