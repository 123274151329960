import { Router } from 'react-router';
import { startErrorLog } from 'utils/handlingErrors';
import { Routes } from 'routes';
import history from 'utils/history';
import ReactTooltip from 'react-tooltip';
import { ToastContainer } from 'react-toastify';
import 'i18n';
import uuid from 'uuid/v4';
import * as Sentry from '@sentry/react';
import CookieAlert from 'containers/Cookies/CookieAlert.jsx';
import PortalProvider from 'containers/Shared/components/Portal/index';
import CallNotificationContainer from 'containers/VideoCall/CallNotificationContainer';
import { useEffect } from 'react';
import useResizeLayout from 'utils/useResizeLayout';
import api from 'api';
import ReactGA from 'react-ga';
import { config } from 'config';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector } from 'react-redux';

const App = () => {
  useResizeLayout();
  const { inicialized, current } = useSelector((state) => state.appointments);
  const { initialized } = useSelector((state) => state.inbox);

  useEffect(() => {
    startErrorLog();

    if (config.services.google.analytics) {
      ReactGA.initialize(config.services.google.analytics);
    }

    if (!localStorage.getItem('sessionId')) {
      const sessionId = uuid();
      localStorage.setItem('sessionId', sessionId);
    }

    if (localStorage.getItem('sessionId')) {
      api.installations.register({
        sessionId: localStorage.getItem('sessionId'),
      });
    }
  }, []);

  const onActive = () => {
    if (!inicialized && !initialized && !current) {
      window.location.reload();
    }
  };

  const { activate } = useIdleTimer({
    onActive,
    timeout: 300_000,
    throttle: 500,
  });

  useEffect(() => {
    activate();
  }, [activate]);

  return (
    <Sentry.ErrorBoundary showDialog={true}>
      <Router history={history}>
        <PortalProvider>
          <Routes />
          <CookieAlert />
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
          />
          <ReactTooltip place="bottom" effect="solid" className="tooltip" />
          <CallNotificationContainer />
        </PortalProvider>
      </Router>
    </Sentry.ErrorBoundary>
  );
};

export default App;
