import axios from 'axios';
import getHeaders from 'utils/getHeaders';
import Tracker from 'utils/Tracking';
import { CreateRQ, RescheduleRQ } from 'api/appointments/index.d';
import moment from 'moment';
import { config } from 'config';

const API = 'professionals/v1';
const BASE_URL = config.services.mediquo.chat_url;

const create = ({
  contactId,
  userHash,
  price,
  roomId,
  withConsultationUrl,
  startsAt,
}: CreateRQ) => {
  Tracker.event('appointment send appointment button click', {
    event_room_id: null, // deprecated
  });

  if (contactId) {
    return axios({
      method: 'POST',
      url: `${BASE_URL}/${API}/appointments`,
      data: {
        to_contact_id: contactId,
        price: parseInt((parseFloat(price) * 100).toString()),
        starts_at: startsAt,
        type: 'future',
        with_consultation_url: withConsultationUrl,
      },
      headers: getHeaders(),
    });
  }

  return axios({
    method: 'POST',
    url: `${BASE_URL}/${API}/rooms/${roomId}/appointments`,
    data: {
      to: userHash,
      roomId,
      price: parseInt((parseFloat(price) * 100).toString()),
      starts_at: startsAt,
      type: 'future',
      with_consultation_url: withConsultationUrl,
    },
    headers: getHeaders(),
  });
};

const getAll = () =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/appointments`,
    headers: getHeaders(),
  });

const getPeriod = (fromStartsAt: number, toStartsAt: number) =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/appointments?from_starts_at=${fromStartsAt}&to_starts_at=${toStartsAt}`,
    headers: getHeaders(),
  });

const get = (appointmentId: string) =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/appointments/${appointmentId}`,
    headers: getHeaders(),
  });

const cancel = (appointmentId: string) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/appointments/${appointmentId}/cancel`,
    headers: getHeaders(),
  });

const reschedule = ({ appointmentId, date, time }: RescheduleRQ) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/appointments/${appointmentId}/reschedule`,
    data: {
      starts_at: moment(`${date} ${time}`).unix(),
    },
    headers: getHeaders(),
  });

const retry = (appointmentId: string) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/appointments/${appointmentId}/retry`,
    headers: getHeaders(),
  });

const dismiss = (appointmentId: string) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/appointments/${appointmentId}/dismiss`,
    headers: getHeaders(),
  });

const fees = (price: any) =>
  axios({
    method: 'GET',
    url: `${BASE_URL}/${API}/fees/calculate?price=${price}`,
    headers: getHeaders(),
  });

const finish = (appointmentId: string, derivationId?: string) => {
  return axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/appointments/${appointmentId}/finish`,
    data: {
      derivation_id: derivationId,
    },
    headers: getHeaders(),
  });
};

const finishFreeCharge = (appointmentId: string) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/appointments/${appointmentId}/finish-free-charge`,
    headers: getHeaders(),
  });

const finishOwe = (appointmentId: string) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/appointments/${appointmentId}/owe`,
    headers: getHeaders(),
  });

const reporting = (appointmentId: string) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/appointments/${appointmentId}/reporting`,
    headers: getHeaders(),
  });

const notPresent = (appointmentId: string) =>
  axios({
    method: 'PUT',
    url: `${BASE_URL}/${API}/appointments/${appointmentId}/miss`,
    headers: getHeaders(),
  });

export default {
  create,
  getAll,
  getPeriod,
  get,
  cancel,
  reschedule,
  retry,
  dismiss,
  fees,
  finish,
  finishFreeCharge,
  finishOwe,
  reporting,
  notPresent,
};
