import { createSlice, createSelector } from '@reduxjs/toolkit';
import { Appointment } from 'api/appointments/index.d';
import { isSameDay } from 'utils/date';
import find from 'utils/find';
import merge from 'utils/merge';

const initialState = {
  inicialized: false,
  current: null,
  items: [],
} as { items: Appointment[]; inicialized: boolean; current: Appointment | any };

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    appointmentsDetailedListFetched(state, action) {
      state.items = action.payload;
      state.inicialized = true;
    },
    setCurrentAppointment(state, action) {
      state.current = action.payload;
    },
    appointmentsPartialListFetched(state, action) {
      action.payload
        ?.filter((item?: Appointment) => !!item)
        ?.forEach((appointmentPayload: Appointment) => {
          merge({ items: state.items, item: appointmentPayload });
        });
    },
    appointmentPartialFetched(state, action) {
      merge({ items: state.items, item: action.payload });
    },
    appointmentDetailedFetched(state, action) {
      merge({ items: state.items, item: action.payload });
    },
    appointmentCreated(state, action) {
      state.items.push(action.payload);
    },
    appointmentCancelled(state, action) {
      changeStatus({
        state,
        id: action.payload.id,
        status: 'cancelled',
      });
    },
    appointmentAccepted(state, action) {
      changeStatus({
        state,
        id: action.payload.id,
        status: 'accepted',
      });
    },
    appointmentDeclined(state, action) {
      changeStatus({
        state,
        id: action.payload.id,
        status: 'declined',
      });
    },
    appointmentExpired(state, action) {
      changeStatus({
        state,
        id: action.payload.id,
        status: 'expired',
      });
    },
    appointmentFinishedFreeCharge(state, action) {
      changeStatus({
        state,
        id: action.payload.id,
        status: 'free_of_charge',
      });
    },
    appointmentFinishedOwe(state, action) {
      changeStatus({
        state,
        id: action.payload.id,
        status: 'owed',
      });
    },
    appointmentFinished(state, action) {
      changeStatus({
        state,
        id: action.payload.id,
        status: 'finished',
        derivationId: action.payload.derivationId,
      });
    },
    appointmentDocumented(state, action) {
      changeStatus({
        state,
        id: action.payload.id,
        reports: action.payload.reports,
      });
    },
    appointmentRetried(state, action) {
      const appointment = find({ items: state.items, id: action.payload.id });
      if (appointment.found) {
        state.items[appointment.index].status = action.payload.status;
        state.items[appointment.index].retried_until =
          action.payload.retried_until;
      }
    },
    appointmentOwed(state, action) {
      changeStatus({
        state,
        id: action.payload.id,
        status: 'owed',
      });
    },
    appointmentDismissed(state, action) {
      const appointment = find({ items: state.items, id: action.payload.id });
      if (appointment.found) {
        state.items[appointment.index].from = {
          ...state.items[appointment.index].from,
          is_dismissed: true,
        };
      }
    },
    appointmentRescheduled(state, action) {
      const appointment = find({ items: state.items, id: action.payload.id });
      if (appointment.found) {
        state.items[appointment.index].start_date = action.payload.start_date;
      }
    },
    appointmentNotPresent(state, action) {
      const appointment = find({ items: state.items, id: action.payload.id });
      if (appointment.found) {
        state.items[appointment.index].no_show = true;
      }
    },
    invitationAccepted(state, action) {
      const appointment = find({
        items: state.items,
        id: action.payload.room_id,
        idAttribute: 'room_id',
      });
      if (appointment.found) {
        state.items[appointment.index].to!.hash = action.payload.hash;
      }
    },
  },
});

const changeStatus = ({ state, id, status, reports, derivationId }: any) => {
  const appointment = find({ items: state.items, id });
  if (appointment.found) {
    if (status) state.items[appointment.index].status = status;
    if (reports) state.items[appointment.index].reports = reports;
    if (derivationId)
      state.items[appointment.index].permissions.consultations.derivations.id =
        derivationId;
  }
};

export const isDetailed = (appointment?: Appointment) =>
  !!appointment?.to && !!appointment?.from;

export const isDismissed = (appointment: Appointment) =>
  appointment.from && appointment?.from?.is_dismissed;

export const HIGHLIGHT_STATUS = [
  'expired',
  'unpaid',
  'finished',
  'pending',
  'free_of_charge',
  'accepted',
  'reporting',
  'cancelled',
  'declined',
  'inCall',
] as const;

export type HighlightStatus = typeof HIGHLIGHT_STATUS[number];

export const appointmentsHighlight = createSelector(
  (state: any) => state.appointments.items,
  (appointments: Appointment[] | null) =>
    appointments
      ?.filter(({ type }) => type === 'future')
      ?.filter(({ status }) => HIGHLIGHT_STATUS.includes(status as any))
);

export const appointmentDatesHighlight = createSelector(
  [appointmentsHighlight],
  (appointments?: Appointment[] | null) =>
    appointments?.map(
      ({ start_date, expires_at }) => new Date((start_date ?? expires_at)!)
    )
);

export const appointmentsHighlightByDate = createSelector(
  ({ state, date }: { state: any; date: Date }) => ({
    appointments: appointmentsHighlight(state),
    date,
  }),
  ({
    appointments,
    date,
  }: {
    appointments?: Appointment[] | null;
    date: Date;
  }) =>
    appointments
      ?.filter(({ start_date, expires_at }) =>
        isSameDay(date, (start_date ?? expires_at)!)
      )
      .sort(earliest)
);

export const isAppointmentsInicialized = createSelector(
  (state: any) => state.appointments.inicialized,
  (inicialized: boolean) => inicialized
);

export const getAppointmentByRoomId = createSelector(
  ({ state, roomId }: { state: any; roomId?: string }) => ({
    items: state.appointments.items,
    roomId,
  }),
  ({ items, roomId }: { items: Appointment[] | null; roomId?: string }) => {
    return items
      ?.filter(({ room_id }) => room_id?.toString() === roomId?.toString())
      .sort(mostRecent)[0];
  }
);

export const getEarliestNonDismissedAppointmentByRoomId = createSelector(
  ({ state, roomId }: { state: any; roomId?: string }) => ({
    items: state.appointments.items,
    roomId,
  }),
  ({ items, roomId }: { items: Appointment[] | null; roomId?: string }) => {
    return items
      ?.filter(({ room_id, start_date, from }) => {
        return [
          // Same room
          room_id?.toString() === roomId?.toString(),

          // Not dismissed
          !from?.is_dismissed,
        ].every(Boolean);
      })
      .sort(earliest)?.[0];
  }
);

const earliest = (a: Appointment, b: Appointment) => {
  if (!b.start_date) return -1;
  if (!a.start_date) return 1;
  return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
};

const mostRecent = (a: Appointment, b: Appointment) => {
  if (!b.created_at) return 1;
  if (!a.created_at) return -1;
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
};

export const getAppointment = createSelector(
  ({ state, id }: { state: any; id: string }) => ({
    items: state.appointments.items,
    id,
  }),
  ({ items, id: appointmentId }: { items: Appointment[] | null; id: string }) =>
    items?.find(({ id }) => id === appointmentId)
);

export const {
  appointmentsDetailedListFetched,
  appointmentsPartialListFetched,
  appointmentPartialFetched,
  appointmentDetailedFetched,
  appointmentCreated,
  appointmentCancelled,
  appointmentAccepted,
  appointmentDeclined,
  appointmentExpired,
  appointmentFinishedFreeCharge,
  appointmentFinishedOwe,
  appointmentFinished,
  appointmentDocumented,
  appointmentRetried,
  appointmentOwed,
  appointmentDismissed,
  appointmentRescheduled,
  appointmentNotPresent,
  invitationAccepted,
  setCurrentAppointment,
} = appointmentsSlice.actions;
export default appointmentsSlice.reducer;
